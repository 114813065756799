<template>
  <div>
    <div
      class="tw-bg-black tw-bg-opacity-30 tw-fixed tw-z-40 tw-top-0 tw-left-0 tw-h-screen tw-w-screen"
    ></div>
    <div
      class="tw-fixed tw-flex tw-justify-center tw-items-center tw-z-50 tw-top-0 tw-left-0 tw-h-screen tw-w-screen"
    >
      <div
        class="tw-bg-white tw-shadow-lg tw-rounded-xl tw-w-10/12 tw-mx-2 sm:tw-mx-0 sm:tw-w-8/12 tw-max-w-md"
      >
        <div class="sm:tw-text-left tw-text-center tw-px-5 tw-py-4">
          <div class="tw-font-bold tw-text-lg">Novo item</div>
        </div>

        <div>
          <div class="tw-mx-5">
            <div
              class="tw-font-normal tw-text-gray-700 tw-uppercase tw-text-sm tw-py-3"
            >
              Tipo de compra
            </div>
            <div class="tw-pb-4">
              <input-select v-model="include.tipo" :items="tiposDeFotos" />
            </div>

            <template v-if="include.tipo !== 'fotos_3x4'">
              <div
                class="tw-font-normal tw-text-gray-700 tw-uppercase tw-text-sm tw-py-3"
              >
                Quantidade de fotos
              </div>
              <div class="tw-pb-4">
                <input
                  type="number"
                  class="inputPadrao"
                  v-model="include.qtde_foto"
                  :items="tiposDeFotos"
                />
              </div>
            </template>
            <div
              class="tw-font-normal tw-text-gray-700 tw-uppercase tw-text-sm tw-py-3"
            >
              Valor da compra
            </div>
            <div class="tw-pb-4">
              <input
                class="inputPadrao"
                v-model.lazy="includeMoney"
                v-money="$root.money"
              />
            </div>
          </div>
          <div
            class="tw-mt-3 tw-text-right tw-grid sm:tw-grid-cols-2 tw-grid-cols-1 tw-bg-gray-100 tw-p-4 tw-rounded-b-lg"
          >
            <div class="tw-text-center tw-m-1">
              <button class="btn cancel" @click="$emit('close')">
                Cancelar
              </button>
            </div>
            <div class="tw-text-center tw-m-1">
              <button
                class="tw-bg-pink-600 tw-transition-colors tw-duration-200 tw-w-4/5 sm:tw-w-3/5 hover:tw-bg-pink-500 tw-p-3 tw-rounded-md tw-text-white"
                @click="cadastrar_item()"
              >
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/service/firebase";
import { addDoc, collection } from "firebase/firestore";
import { required } from "vuelidate/lib/validators";
import { getList } from "@/constants/types/photos";

export default {
  data() {
    return {
      tiposDeFotos: [],
      includeMoney: 0,
      include: {
        qtde_foto: 1,
        tipo: "normal",
        totem_uid: "",
        valor: null,
      },
      selectMenu: false,
    };
  },
  validations: {
    include: {
      valor: {
        required,
      },
      qtde_foto: {
        required,
      },
    },
  },
  beforeMount() {
    this.include.totem_uid = this.$route.params.id;
  },
  mounted() {
    this.tiposDeFotos = [];
    this.tiposDeFotos = [...getList()];
  },
  methods: {
    activeMenuSelect() {
      this.selectMenu = true;
    },
    trataValor() {
      let money = this.includeMoney;
      this.include.valor = money;
      if (this.include.valor.includes(".")) {
        this.include.valor = this.include.valor.replaceAll(".", "");
      }
      if (this.include.valor.includes(",")) {
        this.include.valor = this.include.valor.replaceAll(",", ".");
      }
      if (this.include.valor.includes("R$")) {
        this.include.valor = this.include.valor.replaceAll("R$", "");
      }
      if (this.include.valor.includes(" ")) {
        this.include.valor = this.include.valor.replaceAll(" ", "");
      }
      this.include.valor = Number(this.include.valor);
    },
    validacoes() {
      this.$v.$touch();
      this.trataValor();
      if (this.include.valor < 1) {
        this.$root.errorModal("Valor da compra não pode ser menor que R$ 1,00");
        return false;
      }
      if (this.include.tipo == "fotos_3x4") {
        this.include.qtde_foto = 1;
      }
      if (!this.include.qtde_foto || this.include.qtde_foto < 1) {
        this.$root.errorModal("Quantidade de foto não pode ser menor que 1");
        return false;
      }
      if (this.include.tipo == "polaroid" && this.include.qtde_foto % 2 !== 0) {
        this.$root.errorModal(
          "A polaroid é comprada de 2 em 2, o número passado é ímpar"
        );
        return false;
      }
      return true;
    },
    async cadastrar_item() {
      if (!this.validacoes()) return false;
      let reference = collection(db, "precos");
      let payload = this.include;
      await addDoc(reference, payload)
        .then(() => {
          this.$root.sucessoModal("Item criado com sucesso");
          this.$emit("sucesso");
        })
        .catch((err) => {
          this.$root.errorModal("Ocorreu um erro incomum: " + err);
        });
    },
  },
};
</script>
