<template>
  <div>
    <!--
    <subtitulo class="tw-px-5">Configuração Geral</subtitulo>
    -->
    <div class="tw-p-5">
      <div class="tw-py-2 tw-text-base tw-text-gray-700">
        Alterar o nome do totem
      </div>
      <input
        class="tw-p-2 tw-w-full tw-max-w-md sm:tw-w-2/3 tw-outline-none tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-text-sm"
        type="text"
        v-model="nomeTotem"
        placeholder="Nome do Totem"
      />
      <button
        class="tw-p-2 tw-px-4 tw-rounded tw-border tw-border-solid tw-border-gray-800 tw-bg-gray-800 hover:tw-bg-gray-700 tw-text-white tw-ml-0 sm:tw-ml-2 tw-mt-2 sm:tw-mt-0 tw-transition-colors tw-text-sm tw-font-bold"
        @click="salvarNome"
      >
        salvar
      </button>
    </div>
    <div class="tw-p-5">
      <div class="tw-text-xs tw-text-gray-500">
        <div class="tw-font-normal tw-text-base tw-text-gray-700">
          Url de acesso para instalação do totem:
        </div>
        <div class="tw-text-xs tw-break-all">{{ GET_ACESSO_URL }}</div>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2">
      <div class="tw-p-5">
        <div class="tw-text-base tw-text-gray-700">Impressora(s) ativa(s)</div>
        <div class="tw-text-xs tw-text-gray-500">
          Atenção isso afetará no processo de compra, só ative essa opção após
          configuração da impressora ou quando estiver testando a mesma.
        </div>
        <div>
          <v-checkbox
            label="Polaroid"
            v-model="config.polaroid"
            @change="setToggleConfig"
            hide-details
          ></v-checkbox>
        </div>
        <div>
          <v-checkbox
            label="Normal"
            v-model="config.normal"
            @change="setToggleConfig"
            hide-details
          ></v-checkbox>
        </div>
        <div>
          <v-checkbox
            label="Fotos 3x4"
            v-model="config.fotos_3x4"
            @change="setToggleConfig"
            hide-details
          ></v-checkbox>
        </div>
      </div>
      <div class="tw-p-5">
        <div class="mt-5">
          <div class="tw-text-base tw-text-gray-700">Manutenção</div>
          <div class="tw-text-xs tw-text-gray-500">
            <span class="tw-text-red-600 tw-font-bold">Atenção</span>
            ao ativar essa opção o totem ficará bloqueado para uso.
          </div>
          <div>
            <v-checkbox
              label="Em manutenção"
              v-model="config.manutencao"
              @change="setToggleConfig"
              hide-details
            ></v-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/service/firebase";

export default {
  data() {
    return {
      nomeTotem: null,
      config: {
        polaroid: false,
        normal: false,
        manutencao: false,
      },
    };
  },
  watch: {
    getTotem: {
      handler(payload) {
        this.loadDeliveryData(payload);
      },
      immediate: true,
    },
  },
  mounted() {
    let totemID = this.$route.params.id;
    let totemPayload = this.$store.getters["dashboard/getTotemId"](totemID);
    this.config = totemPayload.config;
    this.config.polaroid = !!totemPayload.config.polaroid;
    this.config.normal = !!totemPayload.config.normal;
    this.config.fotos_3x4 = !!totemPayload.config.fotos_3x4;
    this.config.manutencao = !!totemPayload.config.manutencao;
  },
  methods: {
    async salvarNome() {
      let totemID = this.$route.params.id;
      let nome = this.nomeTotem;
      let docRef = doc(db, "totem", `${totemID}`);
      await updateDoc(docRef, { nome: nome });
      await this.$store.dispatch("dashboard/loadTotens");
    },
    setToggleConfig() {
      let totemID = this.$route.params.id;
      let ref = doc(db, `totem/${totemID}`);
      updateDoc(ref, { config: { ...this.config } }).then(() => {
        this.$store.dispatch("dashboard/loadTotens");
      });
    },
    loadDeliveryData(payload) {
      if (!payload) {
        return false;
      } else {
        this.config.polaroid = payload.config.polaroid;
        this.config.normal = payload.config.normal;
        this.nomeTotem = payload.nome;
      }
    },
  },
  computed: {
    GET_ACESSO_URL() {
      let totemID = this.$route.params.id;
      let totemSelected = this.$store.getters["dashboard/getTotemId"](totemID);
      if (!totemSelected) {
        return "";
      } else {
        return totemSelected.acesso_url;
      }
    },
    getTotem() {
      let totemID = this.$route.params.id;
      return this.$store.getters["dashboard/getTotemId"](totemID);
    },
  },
};
</script>

<style></style>
