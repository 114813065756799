<template>
  <div>
    <div class="list tw-pt-5">
      <div class="tw-grid tw-grid-cols-2 tw-items-center tw-px-5">
        <div class="tw-col-span-1 tw-text-center">
          <div class="tw-w-3/4">
            <input-select v-model="filtro.tipo" :items="getListTypes" />
          </div>
        </div>
        <div class="tw-col-span-1 tw-text-right">
          <div class="inline-block">
            <button
              @click="modalInclude = true"
              class="tw-p-3 tw-px-4 tw-text-sm tw-bg-gray-700 hover:tw-bg-gray-700 tw-transition-colors tw-duration-300 tw-text-white tw-rounded-md"
            >
              <v-icon class="tw-text-white tw-stroke-current">mdi-plus</v-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="listaDeVendas.length == 0"
        class="tw-p-3 tw-mt-8 tw-text-center tw-inline-grid tw-items-center tw-mx-auto"
      >
        <div class="tw-text-center">
          <svg
            id="currentIllo"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 647.63626 632.17383"
            class="tw-w-40 tw-mx-auto"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#f2f2f2"
            ></path>
            <path
              d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#3f3d56"
            ></path>
            <path
              d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#ff68d4"
            ></path>
            <circle cx="190.15351" cy="24.95465" r="20" fill="#ff68d4"></circle>
            <circle
              cx="190.15351"
              cy="24.95465"
              r="12.66462"
              fill="#fff"
            ></circle>
            <path
              d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#e6e6e6"
            ></path>
            <path
              d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#3f3d56"
            ></path>
            <path
              d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#ff68d4"
            ></path>
            <circle
              cx="433.63626"
              cy="105.17383"
              r="20"
              fill="#ff68d4"
            ></circle>
            <circle
              cx="433.63626"
              cy="105.17383"
              r="12.18187"
              fill="#fff"
            ></circle>
          </svg>
        </div>
        <div class="tw-px-4 tw-mt-3 tw-text-gray-400 tw-text-lg tw-font-light">
          Sem itens para a venda
        </div>
      </div>
      <div class="tw-px-4" v-else>
        <div
          class="tw-mt-2 tw-flex tw-flex-wrap tw-justify-left tw-items-left tw-justify-items-left tw-content-left"
        >
          <vItemVenda
            :key="index"
            v-for="(produto, index) in GET_ITENS_FILTERED"
            :produto="produto"
            @editar="editarItem"
            @remove="removeItem"
          />
        </div>
      </div>
    </div>
    <vModalEditor
      v-if="modalEditor"
      :item="selectedItem"
      @sucesso="closeAndUpdate"
      @close="modalEditor = false"
    />
    <vModalInclude
      @sucesso="closeAndUpdate"
      @close="modalInclude = false"
      v-if="modalInclude"
    />
  </div>
</template>

<script>
import {
  collection,
  where,
  query,
  getDocs,
  orderBy,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { db } from "@/service/firebase";
import vModalInclude from "./modalNovo.vue";
import vModalEditor from "./modalEditar.vue";
import vItemVenda from "./itemVenda.vue";
import { getList } from "@/constants/types/photos";

export default {
  components: {
    vModalInclude,
    vModalEditor,
    vItemVenda,
  },
  data() {
    return {
      selectedItem: {},
      tiposLista: [],
      filtro: {
        tipo: "",
      },
      modalEditor: false,
      modalInclude: false,
      listaDeVendas: [],
    };
  },
  beforeMount() {
    this.requestItensForSale();
    this.tiposLista = [...getList()];
    this.tiposLista.unshift({
      id: "",
      name: "Todos",
    });
  },
  computed: {
    getListTypes() {
      return this.tiposLista;
    },
    GET_ITENS_FILTERED() {
      return this.listaDeVendas.filter((item) => {
        let typeItem = item.tipo;
        let queryType = this.filtro.tipo;
        return typeItem.match(queryType);
      });
    },
  },
  methods: {
    editarItem(item) {
      this.selectedItem = item;
      this.modalEditor = true;
    },
    async removeItem(itemVenda) {
      let ref = doc(db, "precos", `${itemVenda.id}`);
      await deleteDoc(ref)
        .then(() => {
          let index = this.listaDeVendas.findIndex(
            (item) => item.id === itemVenda.id
          );
          this.listaDeVendas.splice(index, 1);
          this.$root.sucessoModal("Excluido com sucesso");
        })
        .catch(() => {
          this.$root.errorModal("Não foi possivel excluir o item");
        });
    },
    closeAndUpdate() {
      this.modalInclude = false;
      this.modalEditor = false;
      this.requestItensForSale();
    },
    async requestItensForSale() {
      this.listaDeVendas = [];
      let referencia = collection(db, "precos");
      let totemID = this.$route.params.id;
      let q = query(
        referencia,
        where("totem_uid", "==", totemID),
        orderBy("valor", "asc")
      );
      var snapshot = await getDocs(q);
      snapshot.forEach((item) => {
        let proposed = { id: item.id, ...item.data() };
        this.listaDeVendas.push(proposed);
      });
    },
  },
};
</script>
