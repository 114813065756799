<template>
  <div>
    <div class="text-center" v-if="!sucesso">
      <v-icon class="tw-animate-spin tw-text-pink-600" size="50"
        >mdi-loading</v-icon
      >
    </div>
    <div v-else>
      <div class="tw-px-9 tw-py-1 tw-items-center tw-justify-left tw-flex">
        <button
          @click="goBack()"
          class="tw-bg-transparent tw-rounded-full tw-fill-current tw-group tw-text-text-gray-700 tw-text-xl"
        >
          <v-icon
            size="25"
            color="fill"
            class="tw-text-gray-700 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
            >mdi-arrow-left</v-icon
          >
          <div
            class="tw-inline-block tw-text-gray-500 tw-font-light tw-text-base tw-ml-2"
          >
            VOLTAR
          </div>
        </button>
      </div>
      <div
        id="header"
        class="tw-flex tw-items-center tw-text-center tw-px-5 tw-bg-gray-100"
      >
        <div class="tw-px-8 tw-items-center tw-justify-center tw-flex">
          <v-icon
            size="35"
            class="tw-absolute tw-stroke-current tw-text-gray-700"
            >mdi-cog</v-icon
          >
        </div>
        <div class="tw-p-4 tw-px-3 sm:tw-px-5 tw-text-left">
          <div class="tw-font-semibold tw-text-gray-600 tw-text-xl">
            Configurações
          </div>
          <div class="tw-font-bold tw-text-gray-600 tw-text-lg"></div>
          <div class="tw-text-gray-500 tw-h-8">{{ getTotemName }}</div>
        </div>
      </div>

      <div>
        <vMenuContainer>
          <vMenuItem
            :key="index"
            v-for="(item, index) in menu"
            :currentPage="page"
            :gotoPage="item.page"
            @clicou="setPage"
          >
            {{ item.name }}
          </vMenuItem>
        </vMenuContainer>
        <div>
          <pageVendaPorQuantidade v-if="page == 'venda'" />
          <pageConfigImpressora v-if="page == 'geral'" />
          <pageConfigPagamento v-if="page == 'pagamentos'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { etapaName } from "@/constants/etapaCompra";
import pageVendaPorQuantidade from "./quantidadeVenda/index.vue";
import pageConfigImpressora from "./geral/index";
import pageConfigPagamento from "./pagamentos/ConfigPagamentos.vue";
import vMenuContainer from "./MenuContain.vue";
import vMenuItem from "./MenuItem.vue";

export default {
  components: {
    pageVendaPorQuantidade,
    pageConfigImpressora,
    pageConfigPagamento,
    vMenuContainer,
    vMenuItem,
  },
  beforeMount() {
    this.verificaExistenciaLocal();
  },
  data() {
    return {
      page: "geral",
      countFail: 0,
      sucesso: false,
      menu: [
        {
          page: "geral",
          name: "Geral",
        },
        {
          page: "venda",
          name: "Itens á Venda",
        },
        {
          page: "pagamentos",
          name: "Pagamentos",
        },
      ],
    };
  },
  filters: {
    getDataPayload(val) {
      return moment(val).format("DD/MM/YYYY - HH:mm:ss");
    },
    filtraEtapa(valor) {
      return etapaName[valor];
    },
  },
  methods: {
    setPage(page) {
      this.page = page;
    },
    gotoReg() {
      this.$router.push(`/dashboard/totem/${this.$route.params.id}/compras`);
    },
    verificaExistenciaLocal() {
      let totens = this.$store.getters["dashboard/getTotens"];
      let totemSearch = totens.find((item) => item.id == this.$route.params.id);
      if (totemSearch) {
        this.sucesso = true;
      } else {
        // ! DEV this.countFail ????
        if (this.countFail > 10) {
          this.$root.infoModal("Totem ou permissão não encontrada");
          this.$router.push("/dashboard/totem/lista-totem");
        } else {
          setTimeout(() => {
            this.verificaExistenciaLocal();
          }, 500);
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    getTotemName() {
      let totemID = this.$route.params.id;
      let totem = this.$store.getters["dashboard/getTotemId"](totemID);
      if (totem && totem.nome) {
        return totem.nome;
      } else {
        return "";
      }
    },
  },
};
</script>
