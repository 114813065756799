<template>
  <button
    @click="clique()"
    class="tw-mx-2 tw-bg-pink-600 tw-rounded tw-text-pink-700 tw-text-opacity-80 tw-p-4 tw-px-8 tw-text-sm tw-font-normal tw-transition-colors tw-duration-100"
    :class="{
      'tw-bg-opacity-0 hover:tw-bg-opacity-5': currentPage !== gotoPage,
      'tw-bg-opacity-10': currentPage == gotoPage,
    }"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: String,
      require: true,
    },
    gotoPage: {
      type: String,
      require: true,
    },
  },
  methods: {
    clique() {
      this.$emit("clicou", this.gotoPage);
    },
  },
};
</script>
