<template>
  <div>
    <div
      class="tw-p-6 tw-mb-5 tw-bg-yellow-200 tw-text-yellow-700 tw-w-full tw-mx-auto"
    >
      <strong>Atenção</strong>
      <div>Essa configuração poderá implicar diretamente nas compras</div>
    </div>
    <div class="tw-px-6">
      <div>Módulos de pagamento</div>
      <div class="tw-pb-5">
        <div>
          <v-checkbox
            label="Cartão de Crédito"
            v-model="config.pagamento_cartao_credito"
            @change="updateDB"
            hide-details
          ></v-checkbox>
        </div>
        <div>
          <v-checkbox
            label="Cartão de Débito"
            v-model="config.pagamento_cartao_debito"
            @change="updateDB"
            hide-details
          ></v-checkbox>
        </div>
        <div>
          <v-checkbox
            label="Pix"
            v-model="config.pagamento_pix"
            @change="updateDB"
            hide-details
          ></v-checkbox>
        </div>
      </div>
      <div>
        <div class="tw-text-base tw-text-gray-700">Desativar pagamento</div>
        <div class="tw-text-xs tw-text-gray-500">
          <span class="tw-text-red-600 tw-font-bold">Atenção</span>
          ao ativar essa opção não será solicitado o pagamento.
        </div>
        <div>
          <v-checkbox
            label="Desativar Pagamento"
            v-model="config.pular_etapa_pagamento"
            @change="updateDB"
            hide-details
          ></v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serviceFirebase from "@/service/serviceFirebase";

export default {
  data() {
    return {
      totemUID: null,
      config: {
        pagamento_cartao_credito: null,
        pagamento_cartao_debito: null,
        pagamento_pix: null,
        pular_etapa_pagamento: false,
      },
    };
  },
  mounted() {
    this.totemUID = this.$route.params.id;
    this.carregaInfo();
  },
  methods: {
    carregaInfo() {
      let totemPayload = this.$store.getters["dashboard/getTotemId"](
        this.totemUID
      );
      this.config = totemPayload.config;
      this.config.pular_etapa_pagamento =
        !!totemPayload.config.pular_etapa_pagamento;
      this.config.pagamento_pix = !!totemPayload.config.pagamento_pix;
      this.config.pagamento_cartao_credito =
        !!totemPayload.config.pagamento_cartao_credito;
      this.config.pagamento_cartao_debito =
        !!totemPayload.config.pagamento_cartao_debito;
    },
    updateDB() {
      serviceFirebase
        .updateDadosTotem(this.totemUID, { config: { ...this.config } })
        .then(() => {
          this.$store.dispatch("dashboard/loadTotens");
          return;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style></style>
