const typesPhotos = [
  {
    id: "normal",
    name: "Normal",
  },
  {
    id: "polaroid",
    name: "Polaroid",
  },
  {
    id: "fotos_3x4",
    name: "Fotos 3x4",
  },
];

export const getList = () => {
  return typesPhotos;
};

export const getById = (id) => {
  return typesPhotos.find((item) => item.id === id);
};

export default typesPhotos;
