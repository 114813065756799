<template>
  <div
    class="tw-p-4 tw-bg-white tw-m-4 sm:tw-m-1 tw-rounded-md tw-shadow-lg sm:tw-w-48 tw-w-full"
  >
    <div class="tw-mb-2">
      <div
        class="tw-font-bold tw-text-center tw-text-base tw-text-gray-600 tw-uppercase"
      >
        {{ produto.tipo }}
      </div>
    </div>
    <div class="tw-p-2 tw-text-center tw-text-gray-200">
      <div class="tw-m-1 tw-font-light tw-text-lg tw-text-gray-500">
        {{ produto.qtde_foto }} unidades
      </div>
      <div class="tw-m-1 tw-font-light tw-text-lg tw-text-gray-500">
        {{ produto.valor | currency }}
      </div>
    </div>
    <div
      class="tw-py-0 tw-pt-3 tw-grid tw-grid-cols-2 tw-justify-center tw-items-center tw-text-center"
    >
      <div>
        <button
          @click="removeItem()"
          class="tw-mr-1 tw-transition-colors tw-bg-gray-50 hover:tw-bg-gray-100 tw-p-2 tw-rounded-full"
        >
          <v-icon size="25" class="tw-stroke-current tw-text-red-600"
            >mdi-delete</v-icon
          >
        </button>
      </div>
      <div>
        <button
          @click="editarItem()"
          class="tw-mr-1 tw-transition-colors tw-bg-gray-50 hover:tw-bg-gray-100 tw-p-2 tw-rounded-full"
        >
          <v-icon size="25" class="tw-stroke-current tw-text-yellow-600"
            >mdi-pencil</v-icon
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editarItem() {
      this.$emit("editar", this.produto);
    },
    removeItem() {
      this.$emit("remove", this.produto);
    },
  },
};
</script>

<style></style>
