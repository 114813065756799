const etapa = {
  TIPO_FOTO: "TIPO_FOTO",
  QTDE_FOTO: "QTDE_FOTO",
  SCAN_QR_CODE: "SCAN_QR_CODE",
  UPLOAD: "UPLOAD",
  EDICAO: "EDICAO",
  PAGAMENTO: "PAGAMENTO",
  PAGAMENTO_FALHOU: "PAGAMENTO_FALHOU",
  PAGAMENTO_SUCESSO: "PAGAMENTO_SUCESSO",
  IMPRESSAO: "IMPRESSAO",
  AGRADECIMENTO: "AGRADECIMENTO",
  CONCLUIDO: "CONCLUIDO",
  EXPIROU: "EXPIROU",
  CANCELADA: "CANCELADA",
};
const etapaName = {
  TIPO_FOTO: "Tipo de Foto",
  QTDE_FOTO: "Quantidade de Foto",
  SCAN_QR_CODE: "Scan QRCODE",
  UPLOAD: "Upload",
  EDICAO: "Edição",
  PAGAMENTO: "Pagamento",
  PAGAMENTO_FALHOU: "Pagamento Falho",
  PAGAMENTO_SUCESSO: "Pagamento Sucesso",
  IMPRESSAO: "Impressão",
  AGRADECIMENTO: "Agradecimento",
  CONCLUIDO: "Concluído",
  EXPIROU: "Expirou",
  CANCELADA: "Cancelada",
};
export default etapa;
export { etapaName };
