import { doc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";

export default {
  async updateDadosTotem(totemID, dados) {
    return new Promise((resolve, reject) => {
      if (!totemID || !dados) {
        return reject("TotemId ou dados não existem no payload do request");
      } else {
        let ref = doc(db, `totem/${totemID}`);
        updateDoc(ref, { ...dados })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
};
