<template>
  <div>
    <div
      class="tw-bg-black tw-bg-opacity-30 tw-fixed tw-z-40 tw-top-0 tw-left-0 tw-h-screen tw-w-screen"
    ></div>
    <div
      class="tw-fixed tw-flex tw-justify-center tw-items-center tw-z-50 tw-top-0 tw-left-0 tw-h-screen tw-w-screen"
    >
      <div
        class="tw-bg-white tw-shadow-lg tw-p-5 tw-rounded-xl tw-w-10/12 tw-mx-2 sm:tw-mx-0 sm:tw-w-8/12 tw-max-w-md"
      >
        <div class="sm:tw-text-left tw-text-center">
          <div class="tw-font-bold tw-text-lg">Editando item</div>
        </div>

        <div class="tw-mt-5">
          <template v-if="include.tipo !== 'fotos_3x4'">
            <div class="tw-w-full tw-text-center tw-my-5">
              <div class="tw-p-2">
                <v-text-field
                  class="tw-m-1"
                  :class="{
                    'tw-bg-red-50':
                      !$v.include.qtde_foto.required &&
                      $v.include.qtde_foto.$dirty,
                  }"
                  label="Quantidade de fotos"
                  type="number"
                  v-model.number="$v.include.qtde_foto.$model"
                  hide-details
                />
              </div>
            </div>
          </template>
          <div class="tw-w-full tw-text-center tw-my-5">
            <div class="tw-font-normal tw-uppercase">Valor da compra</div>
            <div class="tw-py-2">
              <input
                class="tw-bg-gray-100 tw-p-3 tw-rounded-md tw-outline-none focus:tw-ring-2 focus:tw-ring-pink-500 tw-text-center"
                v-model.lazy="includeMoney"
                v-money="$root.money"
              />
            </div>
          </div>
          <div
            class="tw-mt-3 tw-text-right tw-grid sm:tw-grid-cols-2 tw-grid-cols-1"
          >
            <div class="tw-text-center tw-m-1">
              <button
                class="tw-p-3 tw-transition-colors tw-duration-200 tw-bg-gray-100 hover:tw-bg-gray-200 tw-w-4/5 sm:tw-w-3/5 tw-rounded-md tw-text-gray-600"
                @click="$emit('close')"
              >
                Cancelar
              </button>
            </div>
            <div class="tw-text-center tw-m-1">
              <button
                class="tw-bg-pink-600 tw-transition-colors tw-duration-200 tw-w-4/5 sm:tw-w-3/5 hover:tw-bg-pink-500 tw-p-3 tw-rounded-md tw-text-white"
                @click="cadastrar_item()"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/service/firebase";
import { setDoc, doc } from "firebase/firestore";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      tiposDeFotos: [
        { code: "polaroid", name: "Polaroid" },
        { code: "normal", name: "Normal" },
      ],
      includeMoney: 0,
      include: {
        qtde_foto: null,
        tipo: "polaroid",
        totem_uid: "",
        valor: null,
      },
    };
  },
  validations: {
    include: {
      valor: {
        required,
      },
      qtde_foto: {
        required,
      },
    },
  },
  beforeMount() {
    this.include.totem_uid = this.$route.params.id;
  },
  mounted() {
    this.payloadValues();
  },
  methods: {
    formatPrice(value) {
      //IMPORTANTE DEVIDO AO V-MONEY
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    payloadValues() {
      this.include.valor = this.item.valor;
      this.includeMoney = this.formatPrice(this.item.valor);
      this.include.tipo = this.item.tipo;
      this.include.qtde_foto = this.item.qtde_foto;
    },
    trataValor() {
      let money = this.includeMoney;
      this.include.valor = money;
      if (this.include.valor.includes(".")) {
        this.include.valor = this.include.valor.replaceAll(".", "");
      }
      if (this.include.valor.includes(",")) {
        this.include.valor = this.include.valor.replaceAll(",", ".");
      }
      if (this.include.valor.includes("R$")) {
        this.include.valor = this.include.valor.replaceAll("R$", "");
      }
      if (this.include.valor.includes(" ")) {
        this.include.valor = this.include.valor.replaceAll(" ", "");
      }
      this.include.valor = Number(this.include.valor);
    },
    validacoes() {
      this.$v.$touch();
      this.trataValor();
      if (this.include.valor < 1) {
        this.$root.errorModal("Valor da compra não pode ser menor que R$ 1,00");
        return false;
      }
      if (!this.include.qtde_foto || this.include.qtde_foto < 1) {
        this.$root.errorModal("Quantidade de foto não pode ser menor que 1");
        return false;
      }
      if (this.include.tipo == "polaroid" && this.include.qtde_foto % 2 !== 0) {
        this.$root.errorModal(
          "A polaroid é comprada de 2 em 2, o numero passado é impar"
        );
        return false;
      }
      return true;
    },
    async cadastrar_item() {
      if (!this.validacoes()) return false;
      let refDoc = doc(db, "precos", this.item.id);
      let payload = this.include;
      await setDoc(refDoc, payload)
        .then(() => {
          this.$root.sucessoModal("Item criado com sucesso");
          this.$emit("sucesso");
        })
        .catch((err) => {
          this.$root.errorModal("Ocorreu um erro incomum: " + err);
        });
    },
  },
};
</script>
